@tailwind base;
@layer base {
  h1 {
    @apply text-3xl desktop:text-5xl largeDesktop:text-7xl largeScreen:text-9xl text-primary font-nunito;
  }
  h2 {
    @apply text-2xl desktop:text-4xl largeDesktop:text-6xl largeScreen:text-8xl text-primary font-nunito;
  }
  h3 {
    @apply text-xl desktop:text-2xl largeDesktop:text-4xl largeScreen:text-6xl text-text font-montserrat;
  }
  h4 {
    @apply text-lg desktop:text-xl largeDesktop:text-2xl largeScreen:text-4xl text-text font-montserrat;
  }
  p {
    @apply text-base desktop:text-lg largeDesktop:text-xl largeScreen:text-3xl text-text font-montserrat;
  }
  button {
    @apply px-10 py-2 text-lg desktop:text-xl largeDesktop:text-2xl largeScreen:text-4xl rounded-lg font-nunito font-semibold transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:drop-shadow  focus:outline-none;
  }
  input {
    @apply px-5 py-1.5  block w-full tablet:w-auto mb-4 border-accent border-2 rounded-full focus:border-primary outline-none placeholder:italic duration-300;
  }
  textarea {
    @apply px-3 py-1.5 h-40 border-accent border-2 rounded-3xl focus:border-primary outline-none resize-none placeholder:italic;
  }
  a {
    @apply underline text-primary hover:cursor-pointer;
  }
}

@tailwind components;
@layer components {
  /* BUTTONS */
  .btn-primary {
    @apply bg-primary text-white  desktop:hover:bg-primaryHover desktop:active:bg-accent desktop:focus:ring-accent disabled:opacity-40;
  }
  .btn-secondary {
    @apply bg-accent text-white  desktop:hover:bg-accentHover desktop:active:bg-primary desktop:focus:ring-primary;
  }
  .btn-muted {
    @apply bg-transparent text-text border-solid border-textHover border desktop:hover:bg-textHover desktop:active:bg-text desktop:active:text-white desktop:focus:ring-accent;
  }
  .btn-muted-darkTheme {
    @apply bg-transparent text-lighter border-solid border-primary border desktop:hover:bg-primary desktop:active:bg-primary desktop:active:text-white desktop:focus:ring-accent;
  }
  .btn-small {
    @apply px-2 py-2  bg-transparent text-text border-solid border-textHover border desktop:hover:bg-textHover desktop:active:bg-text desktop:active:text-white desktop:focus:ring-accent;
  }
  .chip {
    @apply bg-transparent text-text border-textHover desktop:hover:bg-accent desktop:hover:text-white desktop:focus:bg-accent desktop:focus:text-white desktop:active:bg-accent desktop:active:text-white desktop:focus:ring-accent border px-5 py-0.5 largeDesktop:px-10 largeDesktop:py-1 largeScreen:px-12 largeScreen:py-1.5 rounded-lg text-base desktop:text-lg largeDesktop:text-xl largeScreen:text-3xl font-nunito font-semibold transition-all duration-300 hover:-translate-y-0.5 hover:drop-shadow focus:outline-none focus:ring;
  }
  .chipLarge {
    @apply bg-transparent text-text border-textHover border px-10 py-1 text-xl desktop:text-2xl largeDesktop:text-4xl largeScreen:text-6xl rounded-lg font-nunito font-semibold transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:drop-shadow  desktop:focus:outline-none desktop:focus:ring;
  }
  .chip-selected {
    @apply bg-accent text-white border-accent desktop:hover:bg-transparent desktop:hover:text-text desktop:hover:border-text;
  }
  .chip-muted {
    @apply bg-textHover text-white border-none desktop:hover:bg-transparent desktop:hover:text-text desktop:hover:border-text;
  }
  .chip-radio {
    @apply bg-transparent border-solid border rounded-lg flex items-center px-4 py-2 mb-2 hover:-translate-y-0.5 hover:drop-shadow focus:outline-none focus:ring transition-all duration-300 hover:cursor-pointer;
  }

  /* TEXT */
  .caption {
    @apply text-xs desktop:text-sm largeDesktop:text-lg largeScreen:text-xl text-textCaption font-montserrat;
  }

  /* WRAPPERS */
  .sectionWrapper {
    @apply p-5 desktop:p-8 bg-white drop-shadow-md rounded-lg h-full;
  }
  .sideSectionWrapper {
    @apply p-5 desktop:p-8 mb-4 desktop:mb-0 desktop:ml-4 desktop:w-2/5 bg-white drop-shadow-md rounded-lg h-fit;
  }
  .btnBottomWrapper {
    @apply absolute bottom-0 w-full text-center;
  }
  .btnTopCornerWrapper {
    @apply absolute top-0.5 right-0.5 desktop:top-2 desktop:right-2 p-1 desktop:p-2 rounded-full hover:border-accent desktop:hover:drop-shadow-md hover:cursor-pointer;
  }
  .modalWrapper {
    @apply absolute inset-0 bg-textHover flex justify-center items-center;
  }
  .modalContent {
    @apply p-5 desktop:p-8 m-5 bg-white drop-shadow-md rounded-lg tablet:w-1/2 tablet:max-w-2xl h-fit;
  }

  /* ITEMS */
  .bouncyBall {
    @apply drop-shadow-md rounded-full h-10 w-10 bg-secondary mx-auto animate-bouncy;
  }
  .dot {
    @apply p-1.5 mr-4 rounded-full bg-secondary;
  }
}

@tailwind utilities;
@layer utilities {
  .background {
    background-image: url('./assets//images//background.png');
  }
  .backgroundDark {
    background-image: url('./assets//images//backgroundDark.png');
  }
}
